/* You can add global styles to this file, and also import other style files */
body {
  padding-bottom: 100px;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

/* Overwrite the default to keep the scrollbar always visible */
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .6);
}

.logo {
  background: url('/assets/images/cydeo-logo.svg') no-repeat;
  height: 50px;
}
